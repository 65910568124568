import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { StorageService } from '@services/logic/storage.service';

import { environment } from '../../../environments/handyhand/environment.dk';
import { User } from '@app/models/user.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private storageService: StorageService,
  ) {}

  generateAnalyticsUserData(userData: any | null) {
    if (!userData) {
      return {};
    }
    return {
      enhanced_conversion_data: {
        email: userData?.email,
        phone_number: userData?.phone ? `+45${userData.phone}` : undefined,
        address: {
          first_name: userData?.firstName ? userData.firstName : undefined,
          last_name: userData?.lastName ? userData.lastName : undefined,
          city: userData?.city ? userData.city : undefined,
          postal_code: userData?.zipCode ? userData.zipCode : undefined,
          country: 'Denmark',
        },
      },
      enhanced_matching_data: {
        email: userData?.email.toLowerCase(),
        phone_number: userData?.phone ? `0045${userData.phone}` : undefined,
        address: {
          first_name: userData?.firstName
            ? userData.firstName?.toLowerCase()
            : undefined,
          last_name: userData?.lastName
            ? userData.lastName?.toLowerCase()
            : undefined,
          city: userData?.city
            ? userData.city?.toLowerCase()?.replace(/\s/g, '')
            : undefined,
          postal_code: userData?.zipCode
            ? userData?.zipCode?.toLowerCase()
            : undefined,
          country: 'dk',
        },
      },
    };
  }

  // A tag to ensure that datalayer is encriched on pageviews
  customNewPageEvent(user?: User, pageName?: string) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      window.dataLayer = window.dataLayer || [];
      const gtmTag = this.filterEmptyValues({
        event: 'page',
        userId: user?.id ?? '',
        gbuuid: this.storageService.getGrowthbookUUID(),
        pageName: pageName ?? '',
        ...this.generateAnalyticsUserData(user),
      });
      window.dataLayer.push(gtmTag);
    }
  }

  filterEmptyValues(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value != null && value !== ''),
    );
  }

  customEventGrowthbookExperiment(trackingCallbackData: any, user: User) {
    if (
      environment.production &&
      environment.env === 'production' &&
      isPlatformBrowser(this.platformId)
    ) {
      window.dataLayer.push({
        ...trackingCallbackData,
        ...this.generateAnalyticsUserData(user),
      });
    }
  }

  customClickOutToAppStore(user?: any) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      const gtmTag = this.filterEmptyValues({
        event: 'click_out_to_app_store_datalayer',
        userId: user?.id ?? '',
        gbuuid: this.storageService.getGrowthbookUUID(),
        ...this.generateAnalyticsUserData(user),
      });
      window.dataLayer.push(gtmTag);
    }
  }
}
